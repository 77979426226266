<template>
  <div style="opacity: 0" class="page-wrapper">
    <Theme0BaseTheHeader />

    <main class="n-main-container main-wrapper">
      <slot />
    </main>

    <div class="page-cover"></div>

    <Theme0BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme0 from '~/components/theme0/AvailableComponentsForTheme0.vue';

export default defineNuxtComponent({
  name: 'Theme0',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme0 },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  head(context) {
    const websiteStore = useWebsiteStore();
    const appMode = useAppMode();

    if (appMode.server && context.ssrContext) {
      const tagScripts = (() => {
        if (process.env.APP_STAGE === 'production') {
          return [
            ...websiteStore.googleTagsScripts,
            {
              key: 'company-analytics',
              type: 'text/javascript',
              src: `/theme0/js/${websiteStore.companyKey}.js`,
              async: false,
            },
          ];
        }
        return [];
      })();

      const meta = [];
      meta.push({
        name: 'google-site-verification',
        content: 'mpGMjGJyQqByUgKjGVQvXEU-n9HWNm3-EB_fRVOi-Sc',
      });

      return {
        link: [
          { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
          { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' },
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap',
          },
          {
            href: '/theme0/css/normalize.css',
            rel: 'stylesheet',
            hid: 'css/normalize.css',
            type: 'text/css',
          },
          {
            href: '/theme0/css/webflow.css',
            rel: 'stylesheet',
            hid: 'css/webflow.css',
            type: 'text/css',
          },
          {
            href: `/theme0/css/${websiteStore.companyKey}/${websiteStore.companyKey}.webflow.css`,
            rel: 'stylesheet',
            key: `css/${websiteStore.companyKey}/${websiteStore.companyKey}.webflow.css`,
            type: 'text/css',
          },
          {
            href: `/theme0/css/${websiteStore.companyKey}/${websiteStore.companyKey}.css`,
            rel: 'stylesheet',
            key: `css/${websiteStore.companyKey}/${websiteStore.companyKey}.css`,
            type: 'text/css',
          },
        ],
        script: [
          {
            hid: 'jquery-3.5.1',
            src: `/theme0/js/jquery-3.5.1.min.dc5e7f18c8.js`,
            body: true,
            async: false,
          },
          {
            id: 'icebergdigital',
            src: `/theme0/js/icebergdigital.js`,
            body: false,
            type: 'text/javascript',
          },
          {
            id: 'webflow',
            src: `/theme0/js/webflow.js`,
            body: true,
            type: 'text/javascript',
            async: true,
          },
          {
            src: `https://www.google.com/recaptcha/api.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            id: 'vimeo',
            src: 'https://player.vimeo.com/api/player.js',
            body: true,
          },
          {
            id: 'seoplatform.io',
            src: 'https://cdn.seoplatform.io/injector.js?websiteId=24557',
            'data-cfasync': false,
          },
          ...tagScripts,
        ],
        htmlAttrs: {
          'data-wf-page': '659ec494349831ef9e5c1e31',
          'data-wf-site': '644a6ceae40c04757d3fddbf',
        },
        meta,
      };
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),
  },
});
</script>

<style scoped>
#consentPopup {
  transform: unset !important;
  transform-style: unset !important;
}
.w-richtext p {
  padding-bottom: 0;
  padding-top: 0;
}
.rich-text p {
  margin-bottom: 0;
  margin-top: 0;
}
.text-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.text-ellipsis-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.text-ellipsis-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.object-fit-cover {
  object-fit: cover;
}
@media screen and (min-width: 992px) {
  nav ul li .dropdown-toggle {
    font-size: 12px;
  }
  nav ul li .header-nav-link {
    font-size: 12px;
  }
}
@media screen and (min-width: 1100px) {
  nav ul li .dropdown-toggle {
    font-size: 16px;
  }
  nav ul li .header-nav-link {
    font-size: 16px;
  }
}

.cell-heading {
  grid-column: 1 / -1 !important;
}

.w-richtext a {
  color: inherit !important;
}
.akya-container {
  display: none;
}

@media screen and (min-width: 360px) {
  .rl_gallery21_image {
    min-height: 280px !important;
  }
}

@media screen and (min-width: 390px) {
  .rl_gallery21_image {
    min-height: 320px !important;
  }
}

@media screen and (min-width: 428px) {
  .rl_gallery21_image {
    min-height: 360px !important;
  }
}

@media screen and (min-width: 800px) {
  .rl_gallery21_image {
    min-height: 320px;
  }
}

@media screen and (min-width: 835px) {
  .rl_gallery21_image {
    min-height: 340px;
  }
}

@media screen and (min-width: 900px) {
  .rl_gallery21_image {
    min-height: 400px !important;
  }
}

#filter-body {
  height: calc(100% - 73px);
}

@media screen and (min-width: 320px) {
  #filter-body {
    height: calc(100% - 166px);
  }
}

@media screen and (min-width: 768px) {
  #filter-body {
    height: calc(100% - 98px);
  }
}

@media screen and (min-width: 824px) {
  #filter-body {
    height: calc(100% - 73px);
  }
}

.blog-link-text-card {
  font-family: League Spartan !important;
}
</style>
